import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { 
  Spin, message,
} from 'components/common';
import { varIs } from 'common/var'
import { callGetApi } from 'utils/api';

export default function VideoEmbed() {
  const { id } = useParams()
  const [detailData, setDetailData] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [eventUrl, setEventUrl] = useState('')
  const [chatUrl, setChatUrl] = useState('')

  const onGetDetailData = ({ data }) => {
    setDetailData(data)
    setIsLoading(false)
  }
  const onFailDetailData = () => {
  }

  useEffect(() => {
    setIsLoading(true)
    setDetailData(undefined)
    const params = new URLSearchParams(window.location.search);
    if (params.has('token')) {
      const params0 = {
        token: params.get('token')
      }
      const q = Object.keys(params0).map(k=>k+'='+params0[k]).join('&');
      callGetApi(`akashx/stream/${id}/detail?${q}`, 
        onGetDetailData, onFailDetailData
      )
    }
  }, [id])

  useEffect(() => {
    if (detailData) {
      console.log(detailData)
      // Validation first
      let chatUrl0 = '';
      let eventUrl0 = '';
      if (varIs('akaStream.videoType', detailData.video_type, 'vimeo')) {
        eventUrl0 = detailData.vimeo_url
        if (detailData.vimeo_url && 
          detailData.vimeo_url.indexOf('https://vimeo.com/event/')>=0
        ) {
          let pos = detailData.vimeo_url.lastIndexOf('/')
          let preUrl = detailData.vimeo_url.substring(0, pos)
          let postUrl = detailData.vimeo_url.substring(pos)
          eventUrl0 = preUrl+'/embed'+postUrl
          if (!varIs('akaStream.liveStatus', detailData.live_status, 'recorded')) {
            chatUrl0 = preUrl+'/chat'+postUrl
          }
        } else {
          eventUrl0 = detailData.vimeo_url
          .replace('https://vimeo.com/', 'https://player.vimeo.com/video/')
        }
      } else {
        /*
        eventUrl0 = `${process.env.REACT_APP_ZOOM_URL}/aka_zoom?stream_id=${detailData.id}&username=${myUser.username}&email=${myUser.email}`
        */
      }

      if (!eventUrl0) {
        message.error('Session URL is incorrect')
        return
      }

      setEventUrl(eventUrl0)
      setChatUrl(chatUrl0)
    }
  }, [detailData])

  return (
    <Wrapper>
      <div className='tot-root'>
        {detailData ?  
        <>
          <div 
            className={`str-root`}
          >
            <div className={`str-wrap full-width`}>
              <iframe src={eventUrl} 
                frameborder="0" 
                allow="autoplay; fullscreen; picture-in-picture" 
                allowfullscreen 
                width="100%" height="100%" 
                title={'Vimeo Session'}
              />
            </div>
          </div>
        </>
        : <div style={{ height: '100%' }} />}
        {isLoading && 
        <div className='spin-wrap'>
          <Spin spinning={true} />
        </div>}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .tot-root {
    position: relative;
    height: 100vh;
  }
  .spin-wrap {
    position: absolute;
    left: 0; top: 0;
    right: 0; bottom: 0;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .str-root {
    display: flex;
    justify-content: space-between;
    height: 100vh;
  }
  .str-wrap {
    position: relative;
    height: 100%;
    width: calc(100% - 390px);
    &.full-width {
      width: 100%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    iframe {
      position: absolute;
      top: 0; left: 0;
      bottom: 0; right: 0;
    }
  }
`
