import {
  Progress,
  TablePanel,
  Row,
  Col,
  UserAvatar,
  CountryFlag,
} from "components/common";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { callGetApiWithAuth } from "utils/api";
import MarchMadnessImg from "assets/March-Madness-Tracker-2.jpg";
import { asDate } from 'utils/text';

export default function MarchMadnessPage() {
  const [qualEnrollmentsCnt, setQualEnrollmentsCnt] = useState(0);
  const [qualEnrollments, setQualEnrollments] = useState([]);
  const [top10Users, setTop10Users] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const onGetQualEnrollmentsSuccess = (res) => {
    if (res.success) {
      setQualEnrollmentsCnt(res.data.count);
      setQualEnrollments(res.data.enrollments);
    }

    setIsLoading(false);
  };

  const onGetQualEnrollmentsFail = () => {
    setIsLoading(false);
  };

  const loadQualifiedEnrollments = () => {
    setIsLoading(true);

    callGetApiWithAuth(
      `akashx/report/march_madness/get_qualified_enrollments`,
      onGetQualEnrollmentsSuccess,
      onGetQualEnrollmentsFail
    );
  };

  const onGetTop10UsersSuccess = (res) => {
    if (res.success) {
      setTop10Users(res.data);
    }

    setIsLoading1(false);
  };

  const onGetTop10UsersFail = () => {
    setIsLoading1(false);
  };

  const loadTop10Users = () => {
    setIsLoading1(true);

    callGetApiWithAuth(
      `akashx/report/march_madness/get_top_10_users`,
      onGetTop10UsersSuccess,
      onGetTop10UsersFail
    );
  };

  useEffect(() => {
    loadQualifiedEnrollments();
    loadTop10Users();
  }, []);

  return (
    <Wrapper>
      <div className="card">
        <Row
          className="align-items-center justify-content-center "
          gutter={[24, 24]}
        >
          <Col xs={24} xl={12}>
            <div className="d-flex justify-content-center">
              <img
                src={MarchMadnessImg}
                width={"100%"}
                style={{
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  maxHeight: "450px",
                  objectFit: "cover",
                  borderRadius: "16px",
                }}
              />
            </div>
          </Col>
          <Col xs={24} xl={12}>
            <div className="banner-description">
              <div className="title">Akashx March Madness Tournament</div>
              <div className="mt-4 sub-title">
                Track Your Progress & Win Big!
              </div>
              <div className="mt-2">
                Join the <strong>Akashx March Madness Tournament</strong>, a
                five-round challenge designed to help you grow your business
                while earning incredible rewards along the way. Complete each
                round to unlock bigger prizes and compete for the{" "}
                <strong>$200K Funded Trading Account Grand Prize!</strong>
              </div>
              <div className="mt-3">
                <strong>How It Works:</strong>
                <br />
                - Complete each round in order to advance to the next
                <br />- You can start at any time in March, but all rounds must
                be completed by <strong>March 31st</strong>
                <br />- Only <strong>new $149 enrollments</strong> qualify
                <br />- The <strong>Top 10 enrollers</strong> in New Business
                Volume at the end of the month will win funded accounts or cash
                prizes
              </div>
              <div className="mt-4 d-flex justify-content-center">
                <a
                  className="pdf_view"
                  href="https://mdc-assets.s3.us-east-2.amazonaws.com/AK+March+Madness.pdf"
                  target="_blank"
                >
                  Read Me
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="card">
        <div className="d-flex flex-column" style={{ rowGap: "20px" }}>
          <h5 className="text-white">Qualification Tracker</h5>
          <div className="rank-progress-container">
            <div className="progress-label-container">
              <div className="label">Round 1 (April Membership Waived)</div>
              <div className="value">{(qualEnrollmentsCnt > 1 ? 1 : qualEnrollmentsCnt)} / 1</div>
            </div>
            <div className="rank-progress">
              <Progress
                percent={100 * (qualEnrollmentsCnt >= 1)}
                showInfo={false}
                strokeColor="#c1954a"
                strokeWidth={15}
              />
            </div>
          </div>

          <div className="rank-progress-container">
            <div className="progress-label-container">
              <div className="label">Round 2 ($150 Brand Bucks)</div>
              <div className="value">{ ((qualEnrollmentsCnt - 1) > 2 ? 2 : ( (qualEnrollmentsCnt - 1) < 0 ? 0 : (qualEnrollmentsCnt - 1)))} / 2</div>
            </div>
            <div className="rank-progress">
              <Progress
                percent={100 * ((qualEnrollmentsCnt - 1) / 2)}
                showInfo={false}
                strokeColor="#c1954a"
                strokeWidth={15}
              />
            </div>
          </div>

          <div className="rank-progress-container">
            <div className="progress-label-container">
              <div className="label">Round 3 ($50K Trading Challenge)</div>
              <div className="value">{ ((qualEnrollmentsCnt - 1 - 2) > 3 ? 3 : ((qualEnrollmentsCnt - 1 - 2) < 0 ? 0 : (qualEnrollmentsCnt - 1 - 2))) } / 3</div>
            </div>
            <div className="rank-progress">
              <Progress
                percent={100 * ((qualEnrollmentsCnt - 1 - 2) / 3)}
                showInfo={false}
                strokeColor="#c1954a"
                strokeWidth={15}
              />
            </div>
          </div>

          <div className="rank-progress-container">
            <div className="progress-label-container">
              <div className="label">Round 4 (Akashx Waived For 1 Year)</div>
              <div className="value">{ ((qualEnrollmentsCnt - 1 - 2 -3) > 4 ? 4 : ((qualEnrollmentsCnt - 1 - 2 - 3) < 0 ? 0 : (qualEnrollmentsCnt - 1 - 2 - 3))) } / 4</div>
            </div>
            <div className="rank-progress">
              <Progress
                percent={(100 * (qualEnrollmentsCnt - 1 - 2 - 3)) / 4}
                showInfo={false}
                strokeColor="#c1954a"
                strokeWidth={15}
              />
            </div>
          </div>

          <div className="table-wrap">
            <div className="label">Round 5 (Top 10 Leaderboard)</div>
            <TablePanel
              data={top10Users}
              loading={isLoading1}
              columns={[
                {
                  title: "#",
                  key: "s_no",
                  render: (_, __, index) => <span>{index + 1}</span>,
                },
                {
                  title: "Name",
                  key: "user",
                  render: (_, record) => (
                    <UserAvatar
                      image={record.user.image}
                      title={`${record.user.first_name} ${record.user.last_name}`}
                    />
                  ),
                },
                {
                  title: "Country",
                  key: "country",
                  render: (_, record) => (
                    <div>
                      <CountryFlag country={record.user.country} />
                    </div>
                  ),
                },
                {
                  title: "Personal Enrollments",
                  key: "npe",
                  render: (_, record) => <span>{record.npe}</span>,
                },
                {
                  title: "Business Volume",
                  key: "npev",
                  render: (_, record) => <span>{record.npev}</span>,
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="table-wrap">
        <TablePanel
          data={qualEnrollments}
          loading={isLoading}
          title={"My Enrollments"}
          columns={[
            {
              title: "User ID",
              key: "uuid",
              render: (_, record) => <span>{record.user.uuid}</span>,
            },
            {
              title: "Name",
              key: "user",
              render: (_, record) => (
                <UserAvatar
                  image={record.user.image}
                  title={`${record.user.first_name} ${record.user.last_name}`}
                />
              ),
            },
            {
                title: "Country",
                key: "country",
                render: (_, record) => (
                  <div>
                    <CountryFlag country={record.user.country} />
                  </div>
                ),
              },
            {
              title: "Product",
              key: "title",
              render: (_, record) => <span>{record.product.title}</span>,
            },
            {
              title: "Paid At",
              key: "paid_at",
              render: (_, record) => (
                <span>{asDate(record.paid_at)}</span>
              ),
            },
          ]}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .card {
    border-radius: 20px;
    background: ${(props) => props.theme.palette.cardBg};
    border: 1px solid ${(props) => props.theme.palette.cardBorder};
    padding: 23px;
    margin: 20px 0;
    height: 100%;
  }

  .label {
    font-weight: normal;
    font-size: 14px;
    color: #7f8fa3;
  }

  .rank-progress-container {
    .progress-label-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-weight: normal;
        font-size: 14px;
        color: #7f8fa3;
      }
      .value {
        font-weight: 600;
        font-size: 14px;
        color: #c1954a;
      }
    }
    .rank-progress {
      .ant-progress-inner,
      .ant-progress-bg {
        border-radius: 0px;
      }
    }
  }

  .ant-progress-inner {
    border-radius: 50px !important; /* Makes it more rounded */
  }

  .ant-progress-bg {
    border-radius: 50px !important; /* Ensures the progress bar itself is rounded */
  }

  .table-wrap {
    .cardRoot {
      color: ${(props) => props.theme.palette.text};
      border-radius: 15px;
      background: ${(props) => props.theme.palette.cardBg};
    }

    .ant-table thead tr th {
      background: #2d2921;
      color: ${(props) => props.theme.palette.th};
    }
    .ant-table {
      color: ${(props) => props.theme.palette.text};
      background-color: transparent;
    }
    .tableWrapper .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: transparent;
      opacity: 0.8;
    }
    .ant-table-thead > tr > th {
      border-color: #efedf91a;
    }
    .ant-table-tbody > tr > td {
      border-color: #efedf91a;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: transparent;
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td {
      background: transparent;
    }
  }

  .banner-description {
    font-size: 16px;
    color: #e5e5e5b0;

    .title {
      color: #c1954a;
      font-size: 28px;
      font-weight: 700;
    }

    .sub-title {
      color: white;
      font-size: 20px;
      font-weight: 700;
    }

    strong {
      color: white;
    }

    .pdf_view {
      text-align: center;

      border-radius: 12px;
      background: #c1954a;
      width: 140px;
      padding: 8px 0;
      border: 0 none;
      color: #0d0d0d;
      font-size: 16px;
      img {
        margin-right: 8px;
      }
    }
  }
`;
